<template>
	<section v-if="items.length" class="promotions main-content align-center">
		<div class="row row-header align-center">
			<h2 class="subtitle">{{ subtitle }}</h2>
			<h2>{{ header }}</h2>
			<div v-parse-links v-html="content" />
		</div>
		<div class="row promotions-row align-left flex-row">
			<div v-for="room in items" :key="room.ID" class="flex-column">
				<nuxt-link :to="localePath(`/${room.filename}`)" class="room-image">
					<picture>
						<source v-if="room.imageOverviewWebp" :srcset="room.imageOverviewWebp" type="image/webp" />
						<source :srcset="room.imageOverview" />
						<img loading="lazy" :src="room.imageOverview" :alt="room.imageOverviewAlt" />
					</picture>
				</nuxt-link>
				<div class="content">
					<h3>
						<nuxt-link :to="localePath(`/${room.filename}`)">
							{{ room.header }}
						</nuxt-link>
					</h3>
					<nuxt-link :to="localePath(`/${room.filename}`)" class="button button-arrow">
						<span>{{ defaults[locale].rooms.view }}</span>
						<font-awesome-icon icon="fa-light fa-arrow-right-long" size="1x" />
					</nuxt-link>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="columns column12 align-center" style="margin: 50px auto 0">
				<nuxt-link :to="localePath('rooms')" class="button-no-fill" style="margin: 0 auto">
					{{ defaults[locale].rooms.more }}
				</nuxt-link>
			</div>
		</div>
	</section>
</template>

<script setup>
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchDefaults();

defineProps({
	items: { type: Array, default: () => [] },
	subtitle: { type: String, default: '' },
	header: { type: String, default: '' },
	content: { type: String, default: '' },
});
</script>

<style lang="scss" scoped>
.flex-row {
	gap: 1em;

	.flex-column {
		flex: 1;
		display: flex;
		flex-direction: column;

		.content {
			padding: 10px 0 0;
			flex: 1;
			display: flex;
			flex-direction: column;

			.button {
				margin-top: auto;
			}
		}
	}
}

.room-image {
	display: block;
	overflow: hidden;

	img {
		transition: all 0.3s ease-in-out;
	}

	&:hover img {
		transform: scale(1.03);
	}
}

.button {
	text-transform: none;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: center;
	color: $cta-color;
	background: none;
	border-bottom: 1px solid #c5b9ad;
	padding: 10px 0;
	transition: all 0.3s ease-in-out;

	&:hover {
		color: $cta-color-hover;
		border-color: $cta-color-hover;
	}
}

@media (max-width: 920px) {
	.flex-row .flex-column {
		flex: auto;
	}
}
</style>
