<template>
	<section v-if="reviews.length">
		<div class="review-slider">
			<div ref="emblaCarousel" class="carousel review-slider">
				<div class="carousel-container">
					<div v-for="review in reviews" :key="review.ID" class="review-slide-content">
						<p>{{ review.content }}</p>
						<hr />

						<div class="review-source">
							<div>
								<strong>{{ review.firstName }}</strong>
								<span v-if="review.date">
									{{ $d(new Date(review.date.date.split(' ')[0]), 'short') }}
								</span>
							</div>
							<div>
								<div class="logo" :class="review.source" />
								<div class="review-score">
									<font-awesome-icon
										v-for="(icon, index) in ratingToStars(review.rating)"
										:key="`${review.ID}-${icon}-${index}`"
										:icon="`fas ${icon}`"
										size="1x"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="carousel-controls">
					<button
						v-for="(_, index) in reviews"
						:key="index"
						:class="{ active: selectedScrollSnap === index }"
						@click="scrollCarouselTo(index)"
					></button>
				</div>
			</div>
		</div>
	</section>
</template>

<script setup>
import emblaCarouselVue from 'embla-carousel-vue';

const { locale } = useI18n();
const config = useRuntimeConfig();

defineProps({
	header: { type: String, default: '' },
});

const { data: reviews } = await useWebsiteFetch('reviewsSlider', {
	query: { language: locale.value },
	key: `${locale.value}/reviews`,
});

const getReviewTotalAverage = () => {
	const sum = reviews.value
		.filter((review) => review.rating)
		.reduce((total, current) => total + parseInt(current.rating, 10), 0);

	return sum / reviews.value.length;
};

const ratingToStars = (rating) => {
	const fullStars = parseInt(rating / 2, 10);
	const result = Array(fullStars).fill('fa-star');

	if ((rating / 2) % 1 !== 0) {
		result.push('fa-star-half');
	}

	return result;
};

useHead({
	script: [
		{
			type: 'application/ld+json',
			children: JSON.stringify({
				'@context': 'http://schema.org',
				'@type': 'AggregateRating',
				'@id': `${config.public.siteUrl}#aggregaterating`,
				bestRating: 10,
				ratingValue: getReviewTotalAverage(),
				ratingCount: reviews.value.length,
				itemReviewed: {
					'@id': `${config.public.siteUrl}#hotel`,
				},
			}),
		},
	],
});

const [emblaCarousel, emblaApi] = emblaCarouselVue({ loop: true, duration: 20 });
const selectedScrollSnap = ref(0);

const scrollCarouselTo = (index) => {
	emblaApi.value.scrollTo(index);
	selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
};

onMounted(() => {
	if (!emblaApi.value) {
		return;
	}

	emblaApi.value.on('select', () => {
		selectedScrollSnap.value = emblaApi.value.selectedScrollSnap();
	});
});
</script>

<style lang="scss" scoped>
.review-slider {
	padding: 15px 0 25px;
	overflow: hidden;

	.review-slide-content {
		user-select: none;
		text-align: left;

		hr {
			margin: 25px 0;
			opacity: 0.2;
		}
	}
}

.review-source {
	display: flex;
	flex-flow: row wrap;
	gap: 15px;
	margin: 34px 0 0;

	> div {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
	}

	.logo {
		width: 30px;
		height: 30px;
		background-size: 30px 30px;
		margin: 0 20px 0 0;

		&.tripadvisor {
			background-image: url('~/assets/images/review-logo-tripadvisor.png');
		}

		&.bookingcom {
			background-image: url('~/assets/images/review-logo-bookingcom.png');
		}

		&.zoover {
			background-image: url('~/assets/images/review-logo-zoover.png');
		}

		&.expedia {
			background-image: url('~/assets/images/review-logo-expedia.png');
		}

		&.google {
			background-image: url('~/assets/images/review-logo-google.png');
		}
	}
}

.review-source i,
.review-source svg {
	color: $cta-color;
}

.carousel {
	overflow: hidden;
}

.carousel-container {
	display: flex;
	margin-bottom: 40px;
}

.review-slide-content {
	flex: 0 0 100%;
	min-width: 0;
}

.carousel-controls {
	display: flex;
	justify-content: center;

	button {
		width: 12px;
		height: 12px;
		border-radius: 50%;
		background-color: #fff;
		margin: 0 5px;
		cursor: pointer;
		border: none;
		box-shadow: none;

		&.active {
			background-color: #124e36;
		}
	}
}
</style>
