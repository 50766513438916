<template>
	<page-container :page="page">
		<heading-image
			v-if="page.headingImage"
			:heading-image="{
				image: page.headingImage,
				imageAlt: page.headingImageAlt,
				imageWebp: page.headingImageWebp,
				imagePortrait: page.headingImagePortrait,
				imagePortraitWebp: page.headingImagePortraitWebp,
			}"
		>
			<template #heroContent>
				<div v-if="defaults[locale].homepage.headingTitle" class="hero-content">
					<h1 class="hero-title">
						{{ defaults[locale].homepage.headingTitle }}
					</h1>
					<span class="hero-subtitle">
						{{ defaults[locale].homepage.headingSubTitle }}
					</span>
				</div>
			</template>
		</heading-image>

		<main-content :title="page.title || page.header" :subtitle="page.subtitle" :content="page.content">
			<template #content>
				<div class="columns column6">
					<img
						class="intro-image"
						src="~/assets/images/intro-graphic.jpg"
						alt="Hotel Corona"
						loading="lazy"
					/>
				</div>
				<div class="columns column6">
					<div class="title-reverse-wrapper">
						<h2>{{ page.title || page.header }}</h2>
						<h2 class="subtitle">{{ page.subtitle }}</h2>
					</div>
					<div v-parse-links v-html="page.content" />
					<slot name="extraContent" />
				</div>
			</template>
		</main-content>

		<section class="main-content benefits">
			<div class="row">
				<div class="columns column6">
					<div class="content-wrapper">
						<h2 v-if="defaults[locale].homepage.sectionBenefitsSubtitle" class="subtitle">
							{{ defaults[locale].homepage.sectionBenefitsSubtitle }}
						</h2>
						<h2 v-if="defaults[locale].homepage.sectionBenefitsTitle">
							{{ defaults[locale].homepage.sectionBenefitsTitle }}
						</h2>
						<review-slider />
					</div>
				</div>
				<div class="columns column6">
					<picture>
						<source
							v-if="defaults[locale].homepage.sectionBenefitsImageWebp"
							:srcset="defaults[locale].homepage.sectionBenefitsImageWebp"
							type="image/webp"
						/>
						<source :srcset="defaults[locale].homepage.sectionBenefitsImage" />
						<img
							:src="defaults[locale].homepage.sectionBenefitsImage"
							:alt="defaults[locale].homepage.sectionBenefitsImageAlt"
							loading="lazy"
						/>
					</picture>
				</div>
			</div>
		</section>

		<rooms-promotions
			:items="roomsData?.filter((room) => room.showOnIndex)"
			:header="defaults[locale].rooms.sectionRoomsTitle"
			:subtitle="defaults[locale].rooms.subtitle"
			:content="defaults[locale].rooms.sectionRoomsContent"
		/>

		<promoblocks
			:header="defaults[locale].homepage.sectionPromotionTitle"
			:subtitle="defaults[locale].homepage.sectionPromotionSubtitle"
			:items="promoblocksData"
			:amount="5"
		/>

		<contentblocks
			v-if="page && page.contentblocks && page.contentblocks.length"
			:items="page.contentblocks"
			:content-blocks-content="page.sectionContentblocksContent"
			:content-blocks-subtitle="page.sectionContentblocksTitle"
		/>

		<frequently-asked-questions
			v-if="page && page.frequentlyAskedQuestion"
			:header="page.frequentlyAskedQuestion.header"
			:items="page.frequentlyAskedQuestion.faqitems"
		/>

		<pre-footer
			v-if="page && page.footerSection && page.footerSection.length"
			:footer-section="page.footerSection[0]"
		/>
		<newsletter
			v-if="page && page.showNewsletter"
			:header="defaults[locale].newsletter.title"
			:content="defaults[locale].newsletter.content"
			:button="defaults[locale].newsletter.button"
		/>

		<news-overview :news="news" />
	</page-container>
</template>

<script setup>
const { page, fetchPage } = usePage();
const { defaults, fetchDefaults } = useDefaults();
const { locale } = useI18n();

await fetchPage();
await fetchDefaults();

const { data: promoblocksData } = await useWebsiteFetch('promoblocks', {
	query: { language: locale.value },
	key: `${locale.value}/promoblocks`,
});

const { data: news } = await useWebsiteFetch('news', {
	query: { language: locale.value },
	key: `${locale.value}/news`,
});

const { data: roomsData } = await useWebsiteFetch('rooms', {
	query: { language: locale.value },
	key: `${locale.value}/rooms`,
});
</script>

<style lang="scss" scoped>
.intro {
	padding: 100px 0;
	overflow: hidden;
	display: flex;
	flex-flow: row wrap;
	justify-self: center;
	align-items: center;
}

.hero-content {
	position: absolute;
	top: 45%;
	left: 0;
	right: 0;
	z-index: 3;
	text-align: center;
	padding: 0 20px;
	color: #fff;
	animation: fade-from-bottom 1.4s ease;
	max-width: 1000px;
	margin: 0 auto;

	span,
	h1 {
		color: #fff;
		font-weight: 400;
		font-size: 62px;
		letter-spacing: 1px;
		width: 100%;
		font-family: $heading-font-family;
		display: inline-block;
		margin: 0 0 20px;

		&::after {
			display: none;
		}
	}

	.hero-subtitle {
		font-size: 26px;
		font-weight: 300;
		font-family: $body-font-family;
	}
}

.intro-image {
	width: 100%;
	height: auto;
	margin: 0 auto;
	max-width: 540px;
	padding: 0 70px;
}

/* Booking benefits */
.benefits {
	position: relative;
	padding: 0;
	background: $contentblock-background-color;

	.row {
		z-index: 2;
		width: 100%;
		max-width: 100%;
		position: relative;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: stretch;
	}

	.button {
		padding: 10px 25px 10px 16px;

		svg {
			margin: 0 11px 0 0;
		}
	}

	.column6 {
		width: 50%;
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		align-items: stretch;

		&:last-child {
			padding: 0;
		}

		.content-wrapper {
			margin: 0 auto;
			max-width: 700px;
			padding: 100px 50px;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	ul {
		list-style: none;
		font-size: 19px;
		margin: 30px 0;
	}

	li {
		position: relative;
		display: flex;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: flex-start;
		margin: 20px 0;

		svg {
			font-size: 26px;
			margin: 0 20px 0 0;
			color: $cta-color;
		}

		span {
			width: calc(100% - 70px);
		}
	}
}

@media (max-width: 1180px) {
	.benefits .column6 {
		width: 50%;
	}
}

@media (max-width: 920px) {
	.benefits .column6 {
		width: 100%;

		.content-wrapper {
			padding: 60px 20px;
		}

		.button span {
			display: inline-block;
		}
	}

	.intro {
		padding: 50px 0;

		.column6:first-child {
			text-align: center;
		}
	}

	.intro-image {
		max-width: 420px;
		margin: 0 auto 20px;
	}
}

@media (max-width: 880px) {
	.hero-content .hero-title {
		font-size: 56px;
	}

	.hero-content .hero-subtitle {
		font-size: 22px;
	}
}

@media (max-width: 680px) {
	.hero-content .hero-title {
		font-size: 48px;
	}

	.hero-content .hero-subtitle {
		font-size: 20px;
	}
}

@media (max-width: 520px) {
	.hero-content .hero-title {
		font-size: 32px;
	}

	.hero-content .hero-subtitle {
		font-size: 18px;
	}
}
</style>
